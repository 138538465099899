import gql from 'graphql-tag';

const query = gql`query personQuery ($taxCode: String!) {
  person(taxCode: $taxCode) {
    personId
    personInfo{
      name
      surname
      email
    }
    anagraficalInfo {
      taxCode
    }
    score {
      totalPoint
      lastUpdateTotalPoint
      reportReceived {
        confirmedReport {
          countTotal
          countNegative
          countPositive
        }
        sectionTotals {
          key
          value {
            countTotal
            countNegative
            countPositive
          }
        }
      }
      sectionTotals {
        key
        value {
          countTotal
          countNegative
          countPositive
        }
      }
    }
    reportFull{
      status
      documentId
    }
  }
}`;

export default query;
