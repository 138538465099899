var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"border-0"},[_c('div',{staticClass:"mt-3 mb-4"},[_c('h4',{staticClass:"text-center"},[_vm._v("Inserisci il codice fiscale della persona di cui vuoi ottenere informazioni")])]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"mx-md-auto",attrs:{"md":"6","lg":"4"}},[_c('b-form-input',{staticClass:"form-control-lg",attrs:{"placeholder":"Inserisci il codice fiscale"},nativeOn:{"change":function($event){return _vm.currentTaxCodeChange($event)}},model:{value:(_vm.currentSelection),callback:function ($$v) {_vm.currentSelection=$$v},expression:"currentSelection"}})],1)],1),_c('b-row',{staticClass:"my-3"},[_c('b-col',{staticClass:"mx-md-auto",attrs:{"md":"6","lg":"4"}},[_c('div',{class:_vm.searchButtonWrapperClasses},[_c('b-button',{attrs:{"size":"lg","variant":"info","block":"","disabled":!_vm.hasSearchData},on:{"click":_vm.doCdvSearch}},[_vm._v(" ESEGUI LA RICERCA ")])],1)])],1)],1),(_vm.hasSearchResults)?_c('div',{staticClass:"mt-4 mb-5"},[_c('apollo-query',{attrs:{"query":_vm.queryPage,"variables":{ taxCode: _vm.personTax },"fetch-policy":"no-cache","context":{ entityType: _vm.ownerEntityType, entityId: _vm.ownerEntityId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [(loading)?_c('div',{staticClass:"loading apollo"},[_c('div',{staticClass:"my-3 bg-white p-3 text-center"},[_c('loader-info')],1)]):(error)?_c('div',{staticClass:"error apollo"},[_c('div',{staticClass:"my-3 bg-white p-3 text-center"},[_c('p',{staticClass:"mb-3"},[_c('em',[_vm._v(" "+_vm._s(_vm.apolloErrorMsg)+" ")])])])]):(data)?_c('div',{staticClass:"result apollo"},[_c('cdv-search-data-search-item',{attrs:{"data":data.person}})],1):_c('div',{staticClass:"no-result apollo"},[_c('div',{staticClass:"my-3 bg-white p-3 text-center"},[_c('loader-info')],1)])]}}],null,false,4019007156)})],1):_vm._e(),(_vm.hasError)?_c('b-card',{staticClass:"mt-5 my-4 text-center border-0"},[_c('div',{staticClass:"mt-1 mb-4"},[_c('h4',{staticClass:"text-center"},[_vm._v("Il codice fiscale della persona cercata non risulta appartenere al club dei Virtuosi")])]),_c('p',[_vm._v(" Il club dei Virtuosi rappresenta la banca dati in cui sono presenti le persone fisiche le quali, in base alla informazioni presenti sul Portale e alle informazioni derivanti da banche dati terze, si distinguono per la correttezza e il virtuosismo nei rapporti professionali, finanziari, creditizi, commerciali e contrattuali. ")]),_c('p',[_vm._v(" CRPagamenti si astiene dal pubblicare informazioni che possono danneggiare la reputazione delle persone fisiche. Pertanto, non sono disponibili informazioni per le ricerche su persone fisiche assenti dal Club dei Virtuosi, fatte salve le informazioni per cui la pubblicazione è obbligatoria per legge. ")])]):_vm._e(),_c('cdv-search-data-search-modal',{attrs:{"id":_vm.searchModalId,"searchFunction":_vm.doQuickSearch,"searchData":{
      name: _vm.currentSelectionName,
      taxCode: _vm.currentSelectionTaxCode,
      }}}),_c('cdv-search-data-search-modal-info',{attrs:{"id":_vm.infoModalId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }