<template>
  <div>
    <b-card class="border-0">
      <div class="mt-3 mb-4">
        <h4 class="text-center">Inserisci il codice fiscale della persona di cui vuoi ottenere informazioni</h4>
      </div>
      <b-row class="mb-3">
        <b-col md="6" lg="4" class="mx-md-auto">
          <b-form-input
            v-model="currentSelection"
            placeholder="Inserisci il codice fiscale"
            @change.native="currentTaxCodeChange($event)"
            class="form-control-lg"
          />
        </b-col>
      </b-row>
      <b-row class="my-3">
        <b-col md="6" lg="4" class="mx-md-auto">
          <div :class="searchButtonWrapperClasses">
            <b-button
              size="lg"
              variant="info"
              block
              :disabled="!hasSearchData"
              @click="doCdvSearch"
            >
              ESEGUI LA RICERCA
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div
      v-if="hasSearchResults"
      class="mt-4 mb-5"
    >
      <apollo-query
        :query="queryPage"
        :variables="{ taxCode: personTax }"
        fetch-policy="no-cache"
        :context="{ entityType: ownerEntityType, entityId: ownerEntityId }"
      >
        <template v-slot="{ result: { loading, error, data } }">
          <div v-if="loading" class="loading apollo">
            <div class="my-3 bg-white p-3 text-center">
              <loader-info />
            </div>
          </div>

          <!-- Error -->
          <div v-else-if="error" class="error apollo">
            <div class="my-3 bg-white p-3 text-center">
              <p class="mb-3">
                <em>
                  {{apolloErrorMsg}}
                </em>
              </p>
            </div>
          </div>

          <!-- Result -->
          <div v-else-if="data" class="result apollo">
            <cdv-search-data-search-item
              :data="data.person"
            />
          </div>

          <!-- No result -->
          <div v-else class="no-result apollo">
            <div class="my-3 bg-white p-3 text-center">
              <loader-info />
            </div>
          </div>
        </template>
      </apollo-query>
    </div>
    <b-card v-if="hasError" class="mt-5 my-4 text-center border-0">
      <div class="mt-1 mb-4">
        <h4 class="text-center">Il codice fiscale della persona cercata non risulta appartenere al club dei Virtuosi</h4>
      </div>
      <p>
        Il club dei Virtuosi rappresenta la banca dati in cui sono presenti le persone fisiche le
        quali, in base alla informazioni presenti sul Portale e alle informazioni derivanti da
        banche dati terze, si distinguono per la correttezza e il virtuosismo nei rapporti
        professionali, finanziari, creditizi, commerciali e contrattuali.
      </p>
      <p>
        CRPagamenti si astiene dal pubblicare informazioni che possono danneggiare la
        reputazione delle persone fisiche. Pertanto, non sono disponibili informazioni per
        le ricerche su persone fisiche assenti dal Club dei Virtuosi, fatte salve le informazioni
        per cui la pubblicazione è obbligatoria per legge.
      </p>
    </b-card>
    <cdv-search-data-search-modal
      :id="searchModalId"
      :searchFunction="doQuickSearch"
      :searchData="{
        name: currentSelectionName,
        taxCode: currentSelectionTaxCode,
        }"
    />
    <cdv-search-data-search-modal-info :id="infoModalId" />
  </div>
</template>

<script>
import { isNotEmpty, isPresent } from '@/utils/validators';
import personQuery from '@/graphql/personQuery';

// const FieldText = () => import('../fields/FieldText.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const CdvSearchDataSearchItem = () => import('./search/CdvSearchDataSearchItem.vue');
const CdvSearchDataSearchModal = () => import('./search/CdvSearchDataSearchModal.vue');
const CdvSearchDataSearchModalInfo = () => import('./search/CdvSearchDataSearchModalInfo.vue');

export default {
  name: 'ProfileSearchDataSearch',
  components: {
    CdvSearchDataSearchModalInfo,
    CdvSearchDataSearchModal,
    CdvSearchDataSearchItem,
    LoaderInfo,
  },
  props: {},
  data() {
    return {
      options: [],
      currentSelectionName: '',
      currentSelectionTaxCode: '',
      hasError: false,
      hasSearchResults: false,
      companyName: '',
      dataTax: '',
      formReport: {
        person: {
          name: '',
          surname: '',
          taxCode: '',
          totalPoint: '',
        },
      },
      personName: '',
      personTax: '',
      personStatus: '',
      personPoints: '',
      submitted: false,
      completed: false,
      currentSelection: '',
      addNewSubject: false,
      entityType: 'PERSON',
      entityId: '',
      profileData: null,
    };
  },
  computed: {
    apolloErrorMsg() {
      return 'Dati correntemente in elaborazione. Riprova più tardi.';
    },
    apolloNoResultsMsg() {
      return 'Nessun dato disponibile.';
    },

    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    ownerEntityType() {
      return this.isCompanyProfile ? 'company' : 'person';
    },
    ownerEntityId() {
      return this.isCompanyProfile
        ? this.$store.getters['subject/currentCompany']?.companyId
        : this.$store.getters['subject/currentPerson']?.personId;
    },
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    searchButtonWrapperClasses() {
      const a = '';
      if (!this.hasSearchData) return `${a} is-disabled`;
      return a;
    },
    searchList() {
      return this.$store.getters['subject/quickSearchResults'];
    },
    hasSearchData() {
      // console.log(
      //   'isNotEmpty(this.currentSelectionName) && isNotEmpty(this.currentSelectionTaxCode)',
      //   isNotEmpty(this.currentSelectionName) || isNotEmpty(this.currentSelectionTaxCode),
      //   this.currentSelectionName,
      //   this.currentSelectionTaxCode,
      // );
      return isPresent(this.currentSelection);
    },
    searchModalId() {
      return 'searchModal';
    },
    infoModalId() {
      return 'infoModal';
    },
    searchData() {
      return {
        name: this.personName,
        taxCode: this.personTax,
        status: this.personStatus,
        points: this.personPoints,
      };
    },
  },
  mounted() {
    if (!this.isOperative) this.$router.replace('/');
    this.formReport = {
      person: {
        name: '',
        surname: '',
        taxCode: '',
        totalPoint: '',
      },
    };
    this.submitted = false;
    this.completed = false;
    this.hasError = false;
    this.options = [];
    this.currentSelection = '';
    this.addNewSubject = false;
    this.profileData = null;
  },
  methods: {
    onComplete() {
      this.$router.replace(`/crp-profile/${this.entityType}/${this.dataTax}`);
    },
    doQuickSearch() {
      this.$bvModal.hide(this.searchModalId);

      this.hasSearchResults = false;
      this.hasError = false;
      const currentProfile = this.$store.getters['subject/currentCompany'];
      let ownerType;
      let ownerId;

      if (isNotEmpty(currentProfile)) {
        ownerType = 'company';
        ownerId = currentProfile.companyId;
      } else {
        ownerType = 'person';
        ownerId = this.$store.getters['subject/currentPerson'].personId;
      }

      const reportRequest = {
        ownerType,
        ownerId,
        taxCode: this.currentSelectionTaxCode,
        companyName: this.currentSelectionName,
      };

      this.$store.dispatch('subject/quickCompanySearch', reportRequest)
        .then((subject) => {
          console.log('habemus search', subject);
          this.hasSearchResults = true;
        },
        (error) => {
          console.error('Errore in ricerca club virtuosi', error);
          this.hasError = true;
        });
    },
    openSearchModal() {
      // this.$confirm(
      //   {
      //     message: 'Attenzione: ogni ricerca prevede un addebito pari a 1CRP, indipendemente dal risultato della ricerca. Continuare?',
      //     button: {
      //       no: 'No',
      //       yes: 'Si',
      //     },
      //     /**
      //      * Callback Function
      //      * @param {Boolean} confirm
      //      */
      //     callback: (confirm) => {
      //       if (confirm) {
      //         this.doQuickSearch();
      //       }
      //     },
      //   },
      // );
      // console.log('seearchModal?');
      this.$bvModal.show(this.searchModalId);
    },
    // computedCardClass(len) {
    //   // console.log('len', len);
    //   if (len === 1) return 'mx-auto mb-3';
    //   return 'mb-3';
    // },
    openInfo() {
      this.$bvModal.show(this.infoModalId);
    },
    currentTaxCodeChange(e) {
      // console.log('taxt change', e, e.target.value);
      this.currentSelection = e.target.value;
    },
    doCdvSearch() {
      const reporterType = 'PERSON';

      const reportRequest = {
        entityType: reporterType,
        taxCode: this.currentSelection,
      };

      this.completed = false;
      this.submitted = true;
      this.hasError = false;
      this.hasSearchResults = false;
      this.profileData = null;

      this.$store.dispatch('score/searchClub', reportRequest)
        .then((clubSubject) => {
          this.clubSubject = clubSubject.data;
          this.completed = true;
          this.submitted = false;
          console.log('this.reportCreated', this.clubSubject);
          this.personName = `${this.clubSubject.name} ${this.clubSubject.surname}`;
          this.personTax = this.clubSubject.taxCode;
          this.personStatus = this.clubSubject.clubActive ? 'PRESENTE' : 'NON PRESENTE';
          this.personPoints = this.clubSubject.totalPoint;
          this.entityType = this.clubSubject.entityType;
          this.entityId = this.clubSubject.entityId;
          this.hasSearchResults = true;
        },
        (error) => {
          console.error('Errore in ricerca club virtuosi', error);
          this.completed = false;
          this.submitted = false;
          this.hasError = true;
        });
    },
    queryPage() {
      return personQuery;
    },
  },
};
</script>

<style scoped>

</style>
